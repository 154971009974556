export default [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal',
    dataIndex: 'tanggal',
  },
  {
    title: 'Tanggal Stok',
    dataIndex: 'tanggal_stok',
  },
  {
    title: 'Kode Distributor',
    dataIndex: 'distri_code',
  },
  {
    title: 'Nama Distributor',
    dataIndex: 'distri_name',
  },
  {
    title: 'Kode Gudang',
    dataIndex: 'gudang_code',
  },
  {
    title: 'Nama Gudang',
    dataIndex: 'gudang_name',
  },
  {
    title: 'Kode Produk',
    dataIndex: 'product_code',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'product_name',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Stok Awal',
    dataIndex: 'stock_awal',
    slots: { customRender: 'ribuan' },
  },
  {
    title: 'Sell In',
    dataIndex: 'sell_in',
    slots: { customRender: 'ribuan' },
  },
  {
    title: 'Sell Out',
    dataIndex: 'sell_out',
    slots: { customRender: 'ribuan' },
  },
  {
    title: 'Selisih',
    dataIndex: 'selisih',
    slots: { customRender: 'ribuan' },
  },
]
