<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2"
          v-model:value="state.params.region"
        ></filter-regional>
        <filter-province
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region"
        ></filter-province>
        <filter-area
          v-if="!isDistributor && !isDistributor_khusus"
          class="mt-2"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"
        ></filter-area>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="state.params.kabupaten"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"
        ></filter-kabupaten-kota>
        <filter-distributor
          v-if="!isDistributor && !isDistributor_khusus"
          class="mr-2 mt-2"
          v-model:value="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"
        ></filter-distributor>
        <filter-brands class="mr-2 mt-2" v-model:value="state.params.brand" v-model:brand="state.params.brand" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-products class="mr-2 mt-2" v-model:value="state.params.product" v-model:brand="state.params.brand" />
        <a-date-picker
          class="mr-2 mt-2"
          v-model:value="state.params.date"
          placeholder="Pilih Tanggal"
          style="width: 300px"
          format="DD MMMM YYYY"
        />
        <a-input-search
          class="mr-2 mt-2"
          v-model:value="state.params.q"
          placeholder="Cari ..."
          style="width: 300px"
        />
        <a-button
          class="mr-2 mt-2"
          type="primary"
          title="cari"
          @click="fetchData"
          :loading="state.isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <DownloadExcel
          :url="state.endpoint"
          :params="queryParams()"
          namefile="laporan-detail-gudang-distributor"/>
      </div>
    </div>

    <div class="table-responsive">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        bordered
        @change="handleTableChange"
        :loading="state.isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>
        <template #ribuan="{ text }">
          <span>{{ formatRibuan(text) }} </span>
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  nextTick,
  onMounted,
  toRefs,
  onBeforeMount,
  provide,
  watch,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterBrands from '@/components/filter/FilterBrand'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import acolumns from './columns.js'
import moment from 'moment'
import qs from 'qs'
import useUserInfo from '@/composables/useUserInfo'
import { useRoute, useRouter } from 'vue-router'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  name: 'MFilter',
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterDistributor,
    FilterBrands,
    FilterProducts,
    FilterKabupatenKota,
    DownloadExcel,
  },
  setup() {
    const route = useRoute()
    const { isDistributor, vendor_id, isDistributor_khusus } = useUserInfo()
    const errorMessage = ref(null)

    const state = reactive({
      columns: acolumns,
      endpoint: '/api/report/detail-gudang-distributor',
      data: [],
      loading: false,
      isFetching: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        product: [],
        brand: [],
        distributor: [],
        date: moment(new Date()).subtract(1, 'days'),
        page: 1,
        'per-page': 10,
      }),
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
    })

    const handleTableChange = (pag, filters, sorter) => {
      state.params.page = pag.current
      state.params['per-page'] = pag.pageSize
      
      fetchData()
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.date) {
        state.params.date = moment(state.params.date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = () => {
      state.isFetching = true
      apiClient
        .get(state.endpoint, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data
          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      // validation route query default params
      const { distri } = route.query
      if (distri) {
        if (!state.params.distributor.includes(Number(distri)))
          state.params.distributor.push(Number(distri))
      }

      // validation roles default params
      if (isDistributor.value || isDistributor_khusus.value) {
        state.params.distributor.push(vendor_id.value)
      }

      fetchData()
    })

    const formatRibuan = value => {
      return new Intl.NumberFormat(
        'id-ID',
        { maximumFractionDigits: 2 },
        { minimumFractionDigits: 2 },
      ).format(Number(value))
    }

    return {
      state,
      errorMessage,
      fetchData,
      queryParams,
      formatRibuan,
      handleTableChange,
      isDistributor,
      isDistributor_khusus,
    }
  },
})
</script>
